var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "hero is-fullheight",
      staticStyle: { "background-color": "#F1F1F1" },
    },
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c("div", { staticClass: "hero-body" }, [
        _c(
          "div",
          { staticClass: "container", staticStyle: { width: "99vw" } },
          [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }),
              _c("div", { staticClass: "column" }, [
                _c("div", { attrs: { id: "login" } }, [
                  (_vm.phoneNumber && _vm.phoneNumber !== "") ||
                  (_vm.email && _vm.email !== "")
                    ? _c("div", [
                        _c("figure", { staticClass: "has-text-centered" }, [
                          _c("img", {
                            staticStyle: { "max-width": "250px" },
                            attrs: { src: _vm.activeSite.logoUrl },
                          }),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.failedLoginMessage !== "",
                                expression: "failedLoginMessage !== ''",
                              },
                            ],
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-times has-text-danger",
                            }),
                            _vm._v(" " + _vm._s(_vm.failedLoginMessage) + " "),
                            _c("br"),
                            _c("br"),
                          ]
                        ),
                        _c("div", { staticClass: "field" }, [
                          _c("label", { staticClass: "label is-size-7" }, [
                            _vm._v("2FA Code"),
                          ]),
                          _c(
                            "label",
                            { staticClass: "label is-size-7 has-text-grey" },
                            [
                              _vm._v(
                                "The verification code will expire in 10 minutes."
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "control" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.verificationCode,
                                  expression: "verificationCode",
                                },
                              ],
                              ref: "code",
                              class:
                                " input " +
                                (_vm.failedLoginMessage !== ""
                                  ? " is-danger "
                                  : ""),
                              attrs: { type: "text" },
                              domProps: { value: _vm.verificationCode },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.verify()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.verificationCode = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("label", { staticClass: "label is-size-7" }, [
                            _vm._v(
                              "Sent to: " +
                                _vm._s(
                                  _vm.phoneNumber !== ""
                                    ? _vm.phoneNumber
                                    : _vm.email
                                )
                            ),
                          ]),
                          _c(
                            "label",
                            {
                              staticClass: "has-text-grey",
                              staticStyle: {
                                width: "100%",
                                "line-height": ".75rem",
                                "font-size": ".75rem",
                                padding: ".15rem",
                              },
                            },
                            [
                              _vm._v(" Haven't received a code? "),
                              _c(
                                "a",
                                {
                                  class: { "has-text-grey": _vm.codeWasResent },
                                  attrs: {
                                    disabled: _vm.codeWasResent,
                                    href: "javascript:void(0)",
                                  },
                                  on: { click: _vm.resendCode },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeWasResent
                                        ? "Code Sent"
                                        : "Resend Code"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "field is-pulled-right" }, [
                          _c(
                            "div",
                            {
                              staticClass: "control",
                              staticStyle: { "z-index": "100" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-accent",
                                  attrs: {
                                    disabled:
                                      _vm.verificationCode.length !== 6 ||
                                      _vm.isVerifying,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.verify()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isVerifying ? "Verifying" : "Verify"
                                    ) + " "
                                  ),
                                  _vm.isVerifying
                                    ? _c("spin-loader", {
                                        staticStyle: { "margin-left": "5px" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "column" }),
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  "word-wrap": "none",
                  "white-space": "nowrap",
                  "padding-top": "3rem!important",
                  "line-height": ".75rem",
                  "font-size": ".8rem",
                  color: "#00467f",
                  "font-weight": "600",
                  opacity: ".75",
                  "text-align": "center",
                },
              },
              [
                _vm._v(
                  " Please contact BCC Client Services at bccclientservices@bcc-usa.com, if any issues are encountered. "
                ),
              ]
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }